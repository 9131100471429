<template>
  <label class="imageFileInput" :style="`--image:url('${source}')`" :for="`in${id}`" :readonly="readonly">
    <input type="file" :name="name" :accept="accept" @change="readURL" :id="`in${id}`" />
    <iconic v-if="!source" class="icon" name="camera" />
  </label>
</template>

<script>
export default {
  props: ["name", "accept", "value", "readonly"],
  data: function() {
    return {
      source: this.value,
      id: Go.uuid(),
    };
  },
  methods: {
    readURL: function(evt) {
      let [self, input, reader] = [this, evt.target, new FileReader()];

      reader.onload = function(e) {
        self.source = e.target.result;
      };

      if (input.files[0]) {
        reader.readAsDataURL(input.files[0]);
        this.file = input.files[0];
      }
    },
  },
};
</script>

<style lang="scss">
.imageFileInput {
  position: relative;
  display: block;
  background-image: var(--image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 120%;
  cursor: pointer;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .icon {
    opacity: 0.5;
  }
  &[readonly] {
    pointer-events: none;
    cursor: initial;
  }
}
</style>
